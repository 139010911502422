import React, { useState, createContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [sessionJWT, setSessionJWT] = useState('');
  const { instance } = useMsal();
  const [userStatus, setUserStatus] = useState({
    userNotSignedIn: false,
    userTokenIncorrect: false,
    userHasSingedIn: false,
  });
  const activeAccount = instance.getActiveAccount();
  const getSession = async () => {
    try {
      if (activeAccount) {
        const response = await instance.acquireTokenSilent({
          scopes: [
            'openid',
            'profile',
            'https://swenapp.onmicrosoft.com/ae1b2a68-71c8-46b9-b6d5-bfbc1fbafb5e/api.read',
          ],
          account: activeAccount,
        });
        setSessionJWT(response.accessToken);
      }
    } catch (error) {
      console.error('Error acquiring access token:', error);
      instance.logoutRedirect();
    }
  };
  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <AccountContext.Provider
      value={{
        getSession,
        handleLogout,
        sessionJWT,
        userStatus,
        activeAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountProvider, AccountContext };
