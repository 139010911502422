import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Feed from './Feed/Feed';
import Draft from './Draft/Draft';
import Setting from './Setting/Setting';
import Dashboard from './Dashboard/Dashboard';
import Preference from '../Preference/Preference';
import MyArticle from './MyArticle/MyArticle';
import Idea from './Idea/Idea';
import Create from './Create/Create';
import Rule from './Rule/Rule';
import RuleSet from './RuleSet/RuleSet';

const SwenSection = () => {
  return (
    <Routes>
      <Route path='/feed' element={<Feed />} />
      <Route path='/draft/:articleId' element={<Draft />} />
      <Route path='/setting' element={<Setting />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/preference' element={<Preference />} />
      <Route path='/myarticle' element={<MyArticle />} />
      <Route path='/idea' element={<Idea />} />
      <Route path='/create' element={<Create />} />
      <Route path='/rule' element={<Rule />} />
      <Route path='/ruleset' element={<RuleSet />} />
    </Routes>
  );
};

export default SwenSection;
