export const capitalizeChar = (data) => {
  const findCapRegex = /[A-Z]/g;
  const findUnderbarRegex = /[_]/g;
  let str = String(data);

  if (findUnderbarRegex.test(str)) {
    str = str.replace('_', ' ');
  }

  if (findCapRegex.test(str)) {
    if (str?.replace(' ', '')?.length === str.match(findCapRegex)?.length) {
      return str;
    } else {
      for (let i of str.match(findCapRegex)) {
        str = str.replace(i, ` ${i}`);
      }
    }
  }
  str = str[0].toUpperCase() + str.slice(1);

  return str;
};

export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join('');
};
