import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import styled from 'styled-components';

export default function EditRuleModal({ open, onClose, onEdit, rule }) {
  const [name, setName] = useState(rule.name);
  const [description, setDescription] = useState(rule.description);
  const [ruleText, setRuleText] = useState(rule.ruleText);
  const [shared, setShared] = useState(rule.shared);
  const [id, setId] = useState(rule.ruleId);

  useEffect(() => {
    setId(rule.ruleId);
    setName(rule.name);
    setDescription(rule.description);
    setRuleText(rule.ruleText);
    setShared(rule.shared);
  }, [rule]);

  const handleEdit = () => {
    onEdit({ id, name, description, ruleText, shared });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <Typography variant='h6'>규칙 변경</Typography>
        <TextField
          label='규칙 이름'
          fullWidth
          margin='normal'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label='규칙 설명'
          fullWidth
          margin='normal'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          label='규칙'
          fullWidth
          multiline
          rows={8}
          value={ruleText}
          margin='normal'
          onChange={(e) => setRuleText(e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={shared}
              onChange={(e) => setShared(e.target.checked)}
            />
          }
          label='Shared'
          margin='normal'
        />
        <Box mt={2} display='flex' justifyContent='flex-end'>
          <Button onClick={onClose} sx={{ marginRight: 1 }}>
            취소
          </Button>
          <Button variant='contained' onClick={handleEdit}>
            저장
          </Button>
        </Box>
      </ModalBox>
    </Modal>
  );
}

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 680px;
  background-color: white;
  padding: 16px;
  box-shadow: 24px;
  border-radius: 8px;
`;
