import * as React from 'react';
import Chip from '@mui/material/Chip';

export default function KeywordChip({ keyword, inputValues, setInputValues }) {
  const handleRemove = () => {
    setInputValues(inputValues.filter((val) => val !== keyword));
  };

  // Function to trim the label to a certain number of characters
  const trimLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + '...';
    }
    return label;
  };

  // Trimmed label with a maximum of 40 characters
  const trimmedLabel = trimLabel(keyword, 40);

  return (
    <Chip label={trimmedLabel} variant='outlined' onDelete={handleRemove} />
  );
}
