import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from '../../contextApi/AccountContext';
import { BASE_URL, APIs } from '../../config';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Form from './components/Form';
import styled from 'styled-components';
import PopupModal from '../../components/PopupModal';

const AccessCode = () => {
  const { sessionJWT, getSession } = useContext(AccountContext);
  useEffect(() => {
    if (!sessionJWT) {
      getSession().then(() => {});
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useNavigate();

  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    contactBtn: '',
    accessCode: '',
  });
  const [inputData, setInputData] = useState({
    access: { val: '', isCorrect: true },
    workspace: { val: '', isCorrect: false },
    typingTimeout: 0,
    workspace_id: 1,
  });
  const { access, workspace, typingTimeout, workspace_id } = inputData;

  const closePopupModal = () => {
    setPopupModalStatus((prev) => !prev);
  };

  const resetInput = () => {
    closePopupModal();
    setInputData((prev) => {
      return {
        ...prev,
        access: {
          ...inputData[access],
          val: '',
          isCorrect: true,
        },
      };
    });
  };

  const handleContactClick = () => {
    window.open('https://calendly.com/panomix/products');
  };

  const handleInput = ({ target }) => {
    const { name, value } = target;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (name === 'access') {
      setInputData((prev) => {
        return {
          ...prev,
          [name]: {
            ...inputData[name],
            val: value,
          },
        };
      });
    } else {
      setInputData((prev) => {
        return {
          ...prev,
          [name]: {
            ...inputData[name],
            val: value,
          },

          typingTimeout:
            value &&
            setTimeout(() => {
              fetch(`${APIs.search}?q=${value}`, {
                headers: {
                  Authorization: `Bearer ${sessionJWT}`,
                },
                method: 'GET',
              })
                .then((res) => res.json())
                .then((data) => {
                  setInputData((prev) => ({
                    ...prev,
                    [name]: {
                      ...inputData[name],
                      val: value,
                      isCorrect: !!data.name,
                    },
                    workspace_id: data.id,
                  }));
                });
            }, 500),
        };
      });
    }
  };

  const submitInput = async (input, type) => {
    if (type === 'access') {
      fetch(`${BASE_URL}/accesscodes/codecheck`, {
        headers: {
          Authorization: `Bearer ${sessionJWT}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          token: access.val,
        }),
      }).then((res) => {
        if (res.status !== 201) {
          res.json().then(
            (data) =>
              setModalMessage((prev) => {
                return {
                  ...prev,
                  accessCode: data.detail,
                };
              }),
            closePopupModal()
          );
        } else {
          navigate(`/createworkspace`);
        }
      });
    } else {
      const request = await fetch(`${APIs.search}?q=${input}`, {
        headers: { Authorization: `Bearer ${sessionJWT}` },
        method: 'GET',
      });

      const response = await request.json();
      localStorage.setItem('workspace_id', workspace_id);
      navigate(`/${input}/requestaccess`);
    }
  };

  return (
    <>
      <Wrapper>
        <Header>{HEADER_TEXT}</Header>
        <Contact>
          <Title>Request Access Code</Title>
          <Button onClick={handleContactClick}>Contact Us</Button>
        </Contact>
        <Text btnText>Or</Text>
        <Form
          formInfo={FORM_DATA.access}
          input={access.val}
          handleInput={handleInput}
          submitInput={submitInput}
          isCorrect={access.isCorrect}
        />
        <Text>Or</Text>
        <Form
          formInfo={FORM_DATA.workspace}
          input={workspace.val}
          handleInput={handleInput}
          submitInput={submitInput}
          isCorrect={workspace.isCorrect}
        />
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={closePopupModal}
        popupMessage={
          !access.val ? modalMessage.contactBtn : modalMessage.accessCode
        }
        handleConfirmAction={resetInput}
      />
    </>
  );
};

export default AccessCode;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.pb.black};
  font-size: 16px;
  line-height: 19px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 400px;
  border-radius: 42px;
  padding: 0.8rem 11.25rem;
  color: ${({ theme, disabled }) => (disabled ? theme.pb.grey : 'white')};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.pb.lightGrey : theme.pb.midBlue};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  font-weight: 500;
  white-space: nowrap;
  transition: 0.5s;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.pb.darkBlue};
  }
`;

const Text = styled.p`
  padding: ${(props) => (props.btnText ? '2rem ' : '5rem 0 2rem')};
  font-size: 16px;
  line-height: 19px;
`;

const HEADER_TEXT = {
  title: 'Welcome to Swen!',
  subtitle: (
    <>
      Contact us to get access code
      <br />
      to create your workspace!
    </>
  ),
};

const FORM_DATA = {
  access: {
    title: 'Already have an access code?',
    type: 'access',
  },
  workspace: {
    title: 'Already know your workspace name?',
    type: 'workspace',
  },
};
