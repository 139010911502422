import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Chip,
  InputLabel,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import styled from 'styled-components';

export default function EditRuleSetModal({
  open,
  onClose,
  onEdit,
  ruleset,
  availableRules,
}) {
  const [name, setName] = useState(ruleset.name);
  const [description, setDescription] = useState(ruleset.description);
  const [selectedRules, setSelectedRules] = useState(ruleset.rules);
  const [shared, setShared] = useState(ruleset.shared);
  const [id, setId] = useState(ruleset.id);

  useEffect(() => {
    setId(ruleset.id);
    setName(ruleset.name);
    setDescription(ruleset.description);
    setSelectedRules(ruleset.rules);
    setShared(ruleset.shared);
  }, [ruleset]);

  const handleEdit = () => {
    onEdit({ id, name, description, rules: selectedRules, shared });
    onClose();
  };

  const handleRuleChange = (event) => {
    const value = event.target.value;
    setSelectedRules(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <Typography variant='h6'>규칙 세트 변경</Typography>
        <TextField
          label='규칙 세트 이름'
          fullWidth
          margin='normal'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label='규칙 세트 설명'
          fullWidth
          margin='normal'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel id='rules-label'>포함된 규칙들</InputLabel>
          <Select
            labelId='rules-label'
            multiple
            value={selectedRules}
            onChange={handleRuleChange}
            input={<OutlinedInput label='포함된 규칙들' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </Box>
            )}
          >
            {availableRules.map((rule) => (
              <MenuItem key={rule.id} value={rule}>
                {rule.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={shared}
              onChange={(e) => setShared(e.target.checked)}
            />
          }
          label='Shared'
          margin='normal'
        />
        <Box mt={2} display='flex' justifyContent='flex-end'>
          <Button onClick={onClose} sx={{ marginRight: 1 }}>
            취소
          </Button>
          <Button variant='contained' onClick={handleEdit}>
            저장
          </Button>
        </Box>
      </ModalBox>
    </Modal>
  );
}

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 680px;
  background-color: white;
  padding: 16px;
  box-shadow: 24px;
  border-radius: 8px;
`;
