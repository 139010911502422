export const CATEGORY_MAP = {
  technology: '테크놀로지',
  culture: '문화',
  politics: '정치',
  economy: '경제',
  sports: '스포츠',
  entertainment: '엔터테인먼트',
  travel: '여행',
  health: '헬스',
  pressRelease: '보도자료',
  education: '교육',
};

export const FORMAT_MAP = {
  straight: '스트레이트',
  reportage: '리포트',
  interview: '인터뷰',
  listicle: '리스티클',
  editorial: '에디토리얼',
  investigative: '심층취재',
  breaking: '속보',
};

export const STATUS_MAP = {
  generating: '생성중',
  indraft: '작성중',
  inreview: '심의 요청',
  rejected: '반려',
  scheduled: '송고 예약',
  delivered: '송고 완료',
  failed: '생성 실패',
  reviewcomplete: '심의 완료',
};
