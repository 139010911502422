import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function CategorySelect(props) {
  const { category, setCategory } = props;

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const CATEGORY_DATA = [
    '정치',
    '엔터-방송',
    '스포츠',
    '문화-일반',
    '산업',
    '엔터-셀럽',
    '문화-음악',
    '문화-영화',
    '엔터',
    '사회',
    '경제',
    '경제-일반',
    '경제-산업',
    '경제-전자',
    '경제-자동차',
    '경제-IT·통신·게임',
    '경제-에너지·화학',
    '경제-조선·철강·중공업',
    '경제-항공·물류',
    '경제-건설·부동산',
    '경제-유통',
    '라이프',
    '라이프-일반',
    '라이프-푸드',
    '라이프-패션뷰티',
    '라이프-게임',
    '라이프-동물',
    '라이프-건강',
    '라이프-여행',
    '사회-일반',
    '사회-교육',
    '문화',
    '월드',
    '월드-토픽',
    '월드-한류',
    '경제-금융·증권',
    '경제-제약·바이오',
    '라이프-리빙',
    'Global Edition-K-pop',
    'Global Edition-K-Entertainment',
    '보도자료',
  ];

  return (
    <Box sx={{ minWidth: 120, margin: '24px' }}>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>카테고리</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={category}
          label='카테고리'
          onChange={handleChange}
        >
          {CATEGORY_DATA.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
