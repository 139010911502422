import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CustomizedInputBase from './components/CustomizedInputBase';
import LinkChip from './components/LinkChip';
import KeywordChip from './components/KeywordChip';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import { WorkspaceContext } from '../../contextApi/WorkspaceContext';
import LoadingPage from '../../components/LoadingPage';
import { AccountContext } from '../../contextApi/AccountContext';
import { APIs } from '../../config';

const Preference = () => {
  const { sessionJWT } = useContext(AccountContext);
  const { workspaceData, getWorkspaceData } = useContext(WorkspaceContext);
  const { workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [urls, setUrls] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [inputUrl, setInputUrl] = useState('');
  const [inputKeyword, setInputKeyword] = useState('');
  const [currentStep, setCurrentStep] = useState('addUrls');

  useEffect(() => {
    if (workspaceId && sessionJWT) {
      setIsLoading(true);
      const fetchArticle = async () => {
        const request = await fetch(`${APIs('userArticle', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        setUrls(data);
      };
      const fetchKeyword = async () => {
        const request = await fetch(`${APIs('userKeyword', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        setKeywords(data);
      };
      fetchArticle();
      fetchKeyword();
      setIsLoading(false);
    }
  }, [workspaceId, sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddUrl = () => {
    if (inputUrl.trim() !== '') {
      setUrls([...urls, inputUrl]);
      setInputUrl('');
    }
  };

  const handleAddKeyword = () => {
    if (inputKeyword.trim() !== '') {
      setKeywords([...keywords, inputKeyword]);
      setInputKeyword('');
    }
  };

  const handleNext = () => {
    if (currentStep === 'addUrls') {
      setCurrentStep('addKeywords');
    } else if (currentStep === 'addKeywords') {
      setCurrentStep('finished');
    }
  };

  return (
    <Container>
      <Title>Welcome to Swen</Title>
      {currentStep === 'addUrls' && (
        <Section>
          <SectionDescription>
            내가 작성하였거나 좋아하는 기사 혹은 아티클들을 알려주세요. LLM이
            추가 학습하여 나의 스타일을 반영합니다.
          </SectionDescription>
          <CustomizedInputBase
            inputValue={inputUrl}
            setInputValue={setInputUrl}
            label='Add Url'
            onClickHandler={handleAddUrl}
          />
          <ChipSection>
            <Grid container spacing={1}>
              {urls.map((url, index) => (
                <Grid item key={index}>
                  <LinkChip
                    key={index}
                    url={url}
                    inputValues={urls}
                    setInputValues={setUrls}
                  />
                </Grid>
              ))}
            </Grid>
          </ChipSection>
        </Section>
      )}
      {currentStep === 'addKeywords' && (
        <Section>
          <SectionDescription>
            내가 작성하였거나 좋아하는 기사 혹은 아티클들을 알려주세요. LLM이
            추가 학습하여 나의 스타일을 반영합니다.
          </SectionDescription>
          <CustomizedInputBase
            inputValue={inputKeyword}
            setInputValue={setInputKeyword}
            label='Add Keyword'
            onClickHandler={handleAddKeyword}
          />
          <ChipSection>
            <Grid container spacing={1}>
              {keywords.map((keyword, index) => (
                <Grid item key={index}>
                  <KeywordChip
                    key={index}
                    keyword={keyword}
                    inputValues={keywords}
                    setInputValues={setKeywords}
                  />
                </Grid>
              ))}
            </Grid>
          </ChipSection>
        </Section>
      )}
      <FABWrapper>
        <Fab
          color='primary'
          onClick={handleNext}
          disabled={currentStep === 'addUrls' && urls.length === 0}
        >
          <NavigateNextIcon />
        </Fab>
      </FABWrapper>
    </Container>
  );
};

export default Preference;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #fafeff;
`;

const Title = styled.div`
  font-family: 'Arial';
  font-size: 48px;
  text-align: center;
  margin: 40px 0px 40px 0px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
`;

const SectionDescription = styled.div`
  font-size: 14px;
  font-family: 'Arial';
  text-align: center;
  line-height: 22px;
  width: 360px;
  margin: 20px 0px 20px 0px;
  color: #000f1f;
`;

const ChipSection = styled.div`
  display: flex;
  width: 480px;
  margin-top: 40px;
`;

const FABWrapper = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 2;
`;
