import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const CustomButton = styled(Button)(({ active }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: 'none',
  borderRadius: 4,
  borderColor: active ? blue[500] : '#88909B', // Change color when active
  fontFamily: 'Arial',
  color: active ? blue[500] : '#88909B', // Change color when active
  '&:hover': {
    backgroundColor: '#ECF0F2',
    borderColor: active ? blue[500] : '#88909B',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
  '&:focus': {
    backgroundColor: '#ECF0F2',
    borderColor: active ? blue[500] : '#88909B',
  },
}));

export default function ApplyButton({ name, active, onClickHandler }) {
  return (
    <div>
      <CustomButton
        variant='outlined'
        onClick={onClickHandler}
        active={active} // Set active based on number of checked items
      >
        {name}
      </CustomButton>
    </div>
  );
}
