import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function SearchBar(props) {
  const { sessionJWT, workspaceId, setQueryFilter } = props;
  // Feed API Function is passed as a prop for SearchBar.

  const [anchorEl, setAnchorEl] = useState(null);
  const [query, setQuery] = useState('');

  const handleMenuClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    if (query.trim() !== '') {
      // Call API with the searchQuery
      setQueryFilter(query);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const [checked, setChecked] = useState(['filter']);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <Paper
      component='form'
      onSubmit={handleSubmit} // Add this line to handle form submission
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '20px',
      }}
    >
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        placeholder='AI 검색'
        inputProps={{ 'aria-label': 'search news articles' }}
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <IconButton
        type='button'
        sx={{ p: '10px' }}
        aria-label='search'
        onClick={handleSearch}
      >
        <SearchIcon />
      </IconButton>
      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
      <IconButton
        sx={{ p: '10px' }}
        aria-label='menu'
        onClick={handleMenuClick}
      >
        <MenuIcon />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        disablePortal={false}
      >
        <Paper
          sx={{
            marginTop: open ? '8px' : 0, // Add margin when popper is open
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <List
              sx={{
                width: '100%',
                maxWidth: 680,
                bgcolor: 'background.paper',
                borderRadius: 4,
              }}
              subheader={<ListSubheader>Settings</ListSubheader>}
            >
              <ListItem>
                <ListItemIcon>
                  <FilterAltIcon />
                </ListItemIcon>
                <ListItemText
                  id='switch-list-label-filter'
                  primary='Keyword Filter'
                />
                <Switch
                  edge='end'
                  onChange={handleToggle('filter')}
                  checked={checked.indexOf('filter') !== -1}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-filter',
                  }}
                />
              </ListItem>
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper> */}
    </Paper>
  );
}
