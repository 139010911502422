export const COPYRIGHT = {
  KR: 'Panomix © 2024. All Rights Reserved.',
  EN: 'Panomix © 2024. All Rights Reserved.',
};

export const ADDRESS = {
  KR: '대표자﹒한승수﹒사업자등록번호 371-88-00744﹒서울특별시 마포구 성암로 189 13층',
  EN: '13F 189 Seongam-Ro, Mapo-gu, Seoul, Korea',
};

export const TERMS_AND_POLYCY = [
  {
    name: { KR: '개인정보 처리방침', EN: 'Privacy Policy' },
    link: '/privacy',
  },
  {
    name: { KR: '쿠키 정책', EN: 'Cookie Policy' },
    link: '/cookies',
  },
  {
    name: { KR: '서비스 이용약관', EN: 'Terms & Conditions' },
    link: '/terms',
  },
];
