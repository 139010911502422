export const BASE_URL = `https://api.swen-ai.com/api`;
// export const BASE_URL = `${process.env.REACT_APP_API_END_POINT}/api`;

export const APIs = (type, workspaceId) => {
  const apiList = {
    search: `${BASE_URL}/workspaces/search`,
    approve: `${BASE_URL}/workspaces/approve`,
    workspace: `${BASE_URL}/workspaces/${workspaceId}`,
    workspaces: `${BASE_URL}/workspaces/`,
    articles: `${BASE_URL}/articles/${workspaceId}`,
    sources: `${BASE_URL}/sources/${workspaceId}`,
    rules: `${BASE_URL}/rules/${workspaceId}`,
    rulesets: `${BASE_URL}/rules/${workspaceId}/rulesets`,
    // feature: `${BASE_URL}/v2/workspace/${workspaceId}/feature`,
    // integration: `${BASE_URL}/v2/workspace/${workspaceId}/integration`,
    // integratedList: `${BASE_URL}/v2/workspace/${workspaceId}/integration/channels`,
    // plan: `${BASE_URL}/v2/workspace/${workspaceId}/plan`,
    // model: `${BASE_URL}/v2/workspace/${workspaceId}/model`,
    // kpi: `${BASE_URL}/v2/workspace/${workspaceId}/kpi`,
    // campaign: `${BASE_URL}/v2/workspace/${workspaceId}/campaign`,
    myaccount: `${BASE_URL}/users`,
    userArticle: `${BASE_URL}/users/${workspaceId}/preference/article`,
    userKeyword: `${BASE_URL}/users/${workspaceId}/preference/keyword`,
    userFilter: `${BASE_URL}/users/${workspaceId}/preference/filter`,
    member: `${BASE_URL}/workspaces/${workspaceId}/users`,
    // cost: `${BASE_URL}/workspaces/${workspaceId}/cost`,
    // dashboard: `${BASE_URL}/v2/workspace/${workspaceId}/dashboard`,
    // mmmKPI: `${BASE_URL}/v2/workspace/${workspaceId}/model/mmm/kpi`,
    // poc: `${BASE_URL}/v2/workspace/${workspaceId}/poc`,
    // pocResult: `${BASE_URL}/v2/workspace/${workspaceId}/poc/results`,
  };
  return apiList[type];
};
