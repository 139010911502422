import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CATEGORIES_DATA } from '../DATA/FILTER_DATA';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const CategoryButton = styled(Button)(({ active }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: 'none',
  borderRadius: 40,
  borderColor: active ? blue[500] : '#88909B', // Change color when active
  fontFamily: 'Arial',
  color: active ? blue[500] : '#88909B', // Change color when active
  '&:hover': {
    backgroundColor: '#ECF0F2',
    borderColor: active ? blue[500] : '#88909B',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
  '&:focus': {
    backgroundColor: '#ECF0F2',
    borderColor: active ? blue[500] : '#88909B',
  },
}));

export default function CategoryFilter({
  categoryFilters,
  setCategoryFilters,
}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState([]);

  // Initialize checked state based on categoryFilters
  useEffect(() => {
    if (categoryFilters) {
      setChecked(categoryFilters);
    }
  }, [categoryFilters]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setCategoryFilters(newChecked); // Update the categoryFilters state in the parent component
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <div>
      <CategoryButton
        variant='outlined'
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        active={checked.length > 0} // Set active based on number of checked items
      >
        카테고리
      </CategoryButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                marginTop: 1,
                border: 0.2,
                p: 1,
                bgcolor: 'background.paper',
                borderColor: '#88909B',
                borderRadius: 4,
                maxHeight: 360,
                overflowY: 'scroll',
                minWidth: 240,
              }}
            >
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                }}
              >
                {CATEGORIES_DATA.map((value) => {
                  const labelId = `checkbox-list-label-${value}`;

                  return (
                    <ListItem key={value} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={handleToggle(value)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge='start'
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
