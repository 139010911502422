import React, { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APIs } from '../config';
import { AccountContext } from './AccountContext';

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const { sessionJWT } = useContext(AccountContext);
  let { workspaceId } = useParams();
  const [workspaceData, setWorkspaceData] = useState({});

  const getWorkspaceData = async (option) => {
    if (sessionJWT && workspaceId && option) {
      try {
        const request = await fetch(`${APIs(option, workspaceId)}`, {
          headers: {
            Authorization: `Bearer ${sessionJWT}`,
          },
        });
        const response = request.json();
        const data = await response;

        setWorkspaceData((prev) => ({ ...prev, [option]: data }));
      } catch (err) {
        console.error(err);
      }
    } else return;
    return;
  };

  return (
    <WorkspaceContext.Provider
      value={{
        getWorkspaceData,
        workspaceData,
        setWorkspaceData,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error('Cannot get workspace info');
  }
  return context;
};
