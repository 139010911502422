import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LanguageProvider } from '../../contextApi/LanguageContext';
import { AccountContext } from '../../contextApi/AccountContext';

import PanomixNav from '../../components/Nav/PanomixNav';
import PanomixMobileNav from '../../components/Nav/PanomixMobileNav';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';

import CookiePolicy from './TermsAndPrivacy/CookiePolicy';
import Terms from './TermsAndPrivacy/Terms';
import PrivacyPolicy from './TermsAndPrivacy/PrivacyPolicy';

import styled from 'styled-components';
import GenAILogo from '../../assets/logo/GenAI.svg';

import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

export default function Main({ type }) {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    maxWidth: 800,
  });

  const PAGES = {
    cookies: <CookiePolicy />,
    terms: <Terms />,
    privacy: <PrivacyPolicy />,
  };

  const [nextStep, setNextStep] = useState(false);
  const { sessionJWT, getSession } = useContext(AccountContext);

  useEffect(() => {
    if (sessionJWT && sessionJWT !== '') {
      setNextStep(true);
    } else {
      getSession();
    }
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  // Check sessionJWT exists. If not,
  useEffect(() => {
    getSession();
  });

  useEffect(() => {
    nextStep === true && navigate('/workspacelist');
    // Decide where to go here? if workspace not found then onboarding pages.
  }, [nextStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LanguageProvider>
      <Wrapper>
        {isMobile ? <PanomixMobileNav /> : <PanomixNav />}
        <TitleBox>
          <PlaybookIcon src={GenAILogo} />
          <Title isMobile={isMobile}>Welcome to Swen AI</Title>
          <SubTitle isMobile={isMobile}>
            Swen AI is an LLM-based news generation platform. Publish news
            articles in minutes with Swen AI!
          </SubTitle>
          {isMobile && (
            <LinkButtonWrapper>
              <LinkButton isContactUs={true} onClick={handleLoginRedirect}>
                Log in
              </LinkButton>
            </LinkButtonWrapper>
          )}
        </TitleBox>
        <Footer />
      </Wrapper>
      {type && (
        <TermsAndPrivacyWrapper>
          <AlignBox needMarginTop={true}>{PAGES[type]}</AlignBox>
          <Footer />
        </TermsAndPrivacyWrapper>
      )}
    </LanguageProvider>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-size: cover;

  width: 100vw;
  height: 100vh;
`;

const TermsAndPrivacyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;

  width: 100vw;
  height: auto;
`;

const AlignBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin-top: ${({ needMarginTop }) => needMarginTop && '80px'};
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  with: 100vw;
`;

const PlaybookIcon = styled.img`
  width: 75px;
  height: 75px;
  margin-bottom: 37px;
`;

const Title = styled.h1`
  font-size: ${({ isMobile }) => (isMobile ? '24px' : '48px')};
  color: white;
  font-weight: 400;
  margin-bottom: 24px;
  font-family: Poppins;
`;

const SubTitle = styled.h2`
  font-size: ${({ isMobile }) => (isMobile ? '14px' : '18px')};
  font-weight: 400;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? '340px' : '450px')};
  line-height: ${({ isMobile }) => (isMobile ? '18px' : '28px')};
  font-family: Arial;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isContactUs }) => (isContactUs ? '150px' : 'auto')};
  padding: 8px 13px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  background-color: ${({ theme }) => theme.pm.blue};
  color: #fff;
  cursor: pointer;
`;
