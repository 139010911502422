import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { ReactComponent as PanomixLogo } from '../../assets/logo/PanomixLogoWhite.svg';
import styled from 'styled-components';

export default function PanomixMobileNav() {
  const isUnder1440 = useMediaQuery({ maxWidth: 1440 });
  const isMobile = useMediaQuery({
    maxWidth: 800,
  });
  const { language, setLanguage } = useContext(LanguageContext);
  const openLink = (url) => {
    if (url === '/') {
      window.location.replace('/');
    } else {
      window.open(url, '_blank');
    }
  };

  const DEMO_BUTTON_NAME = {
    KR: '데모 신청',
    EN: 'Request Demo',
  };

  return (
    <Wrapper>
      <AlignBox isUnder1440={isUnder1440}>
        <Logo onClick={() => openLink('https://panomix.io')} />
      </AlignBox>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  background-color: ${({ theme }) => theme.neutralColor.dark3};
  height: 72px;

  z-index: 50;
`;

const AlignBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

const Logo = styled(PanomixLogo)`
  width: 120px;
  height: 30px;
  cursor: pointer;
`;

const ButtonContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LanguageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 87px;
  color: #fff;
`;

const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#fff' : '#888888')};
  cursor: pointer;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const LinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: ${({ isContactUs }) => (isContactUs ? '150px' : 'auto')};
  padding: 8px 13px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  background-color: ${({ theme }) => theme.neutralColor.dark1};
  color: #fff;
  cursor: pointer;
`;

const Question = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
