import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  position: 'relative',
  border: isSelected ? `2px solid #0B8FFC` : 'none',
  cursor: 'pointer',
  maxWidth: '360px',
  minWidth: '300px',
  transition: '0.35s',
  flex: '1 1 360px', // Ensures that the cards are flexible but do not exceed 300px
  '&:hover': {
    backgroundColor: '#C9CBCF', // Grey color on hover
  },
}));

export default function RuleSetSelect({
  category,
  ruleset,
  isSelected,
  onCardClick,
}) {
  const handleCardClick = (e) => {
    e.preventDefault();
    onCardClick(category, ruleset);
  };

  return (
    <>
      <StyledCard onClick={handleCardClick} isSelected={isSelected}>
        <CardContent>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              <Typography variant='h5'>{ruleset.name}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {ruleset.description}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </StyledCard>
    </>
  );
}
