import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import RuleCard from './components/RuleCard'; // Ensure the correct path
import CreateRuleModal from './components/CreateRuleModal';
import { Typography, Box, Button } from '@mui/material';
import { APIs } from '../../../config'; // Adjust import based on your project structure
import { useParams } from 'react-router-dom';
import { AccountContext } from '../../../contextApi/AccountContext';
import { useNavigate } from 'react-router-dom';
import PopupModal from '../../../components/PopupModal';

export default function Rule() {
  const { sessionJWT, getSession } = useContext(AccountContext);
  const navigate = useNavigate();
  const clearLocationState = () => navigate('.', { state: null });
  const [isLoading, setIsLoading] = useState(false);
  const { workspaceId } = useParams();
  const [rules, setRules] = useState({ myrules: [], shared: [] });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const closePopupModal = () => {
    setPopupModalStatus(false);
  };

  const openErrorPopup = (err) => {
    PopupModal.popupMessage = err;
    PopupModal.confirmBtnName = 'Close';
    PopupModal.handleConfirmAction = () => closePopupModal();
    setPopupModalStatus(true);
  };
  const onCreate = (data) => {
    const { name, description, ruleText, shared } = data;

    fetch(`${APIs('rules', workspaceId)}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionJWT}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        description: description,
        rule: ruleText,
        shared: shared,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          clearLocationState();
          window.location.reload(true);
        } else if (res.stats === 400) {
          res.json().then((res) => {
            openErrorPopup(`${Object.values(res)[0]}`);
          });
        }
      })
      .catch((err) => console.error('create rule error: ', err));
  };

  useEffect(() => {
    if (workspaceId && sessionJWT) {
      setIsLoading(true);
      const fetchData = async () => {
        const request = await fetch(`${APIs('rules', workspaceId)}`, {
          headers: { Authorization: `Bearer ${sessionJWT}` },
        });
        const data = await request.json();
        if (data) {
          setRules(data);
        } else return;
      };
      fetchData();
      setIsLoading(false);
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditRule = (rule) => {
    const { id, name, description, ruleText, shared } = rule;

    fetch(`${APIs('rules', workspaceId)}/rule/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${sessionJWT}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        description: description,
        rule: ruleText,
        shared: shared,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          clearLocationState();
          window.location.reload(true);
        } else if (res.status === 400) {
          res.json().then((res) => {
            openErrorPopup(`${Object.values(res)[0]}`);
          });
        }
      })
      .catch((err) => console.error('edit rule error: ', err));
  };

  useEffect(() => {
    if (!sessionJWT) {
      getSession();
    } else return;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteRule = (data) => {
    const { id } = data;
    fetch(`${APIs('rules', workspaceId)}/rule/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${sessionJWT}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 200) {
          clearLocationState();
          window.location.reload(true);
        } else if (res.status === 400) {
          res.json().then((res) => {
            openErrorPopup(`${Object.values(res)[0]}`);
          });
        }
      })
      .catch((err) => console.error('edit rule error: ', err));
  };

  return (
    <Wrapper>
      <CreateButtonArea>
        <Button variant='contained' onClick={() => setIsModalOpen(true)}>
          규칙 추가
        </Button>
      </CreateButtonArea>

      <TitleSection>내 규칙</TitleSection>
      <RulesContainer>
        {rules?.myrules?.length > 0 &&
          rules?.myrules?.map((rule, idx) => (
            <RuleCard
              key={idx}
              ruleId={rule.id}
              name={rule.name}
              description={rule.description}
              ruleText={rule.rule}
              shared={rule.shared}
              onEdit={handleEditRule}
              onDelete={() => handleDeleteRule(rule)}
            />
          ))}
      </RulesContainer>

      <TitleSection>공통 규칙</TitleSection>
      <RulesContainer>
        {rules?.shared?.length > 0 &&
          rules?.shared?.map((rule, idx) => (
            <RuleCard
              key={idx}
              ruleId={rule.id}
              name={rule.name}
              description={rule.description}
              ruleText={rule.rule}
              shared={rule.shared}
              onEdit={handleEditRule}
              onDelete={() => handleDeleteRule(rule)}
            />
          ))}
      </RulesContainer>

      <CreateRuleModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={onCreate}
      />
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={closePopupModal}
        popupMessage={PopupModal.popupMessage}
        confirmBtnName={PopupModal.confirmBtnName}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  padding: 20px 40px 80px 40px;
  overflow-y: auto;
`;

const RulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-start;
`;

const TitleSection = styled.div`
  font-size: 16px;
  font-family: 'Arial';
  color: ${({ theme }) => theme.pb.darkGray};
  margin-bottom: 14px;
  margin-top: 32px;
`;

const CreateButtonArea = styled.div`
  margin-top: 8px;
`;
