import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Autocomplete,
  Chip,
} from '@mui/material';
import styled from 'styled-components';

export default function CreateRuleSetModal({
  open,
  onClose,
  onCreate,
  availableRules,
}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedRules, setSelectedRules] = useState([]);
  const [shared, setShared] = useState(false);

  const handleCreate = () => {
    const rules = selectedRules.map((rule) => rule.id);
    onCreate({ name, description, rules, shared });
    setName('');
    setDescription('');
    setSelectedRules([]);
    setShared(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <Typography variant='h6'>새로운 규칙 세트 생성</Typography>
        <TextField
          label='규칙 세트 이름'
          fullWidth
          margin='normal'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label='규칙 세트 설명'
          fullWidth
          margin='normal'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Autocomplete
          multiple
          options={availableRules}
          getOptionLabel={(option) => option.name}
          value={selectedRules}
          onChange={(event, newValue) => setSelectedRules(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='포함된 규칙들'
              placeholder='Rules'
              margin='normal'
            />
          )}
        />
        <FormControlLabel
          control={
            <Switch
              checked={shared}
              onChange={(e) => setShared(e.target.checked)}
            />
          }
          label='Shared'
          margin='normal'
        />
        <Box mt={2} display='flex' justifyContent='flex-end'>
          <Button onClick={onClose} sx={{ marginRight: 1 }}>
            취소
          </Button>
          <Button variant='contained' onClick={handleCreate}>
            생성
          </Button>
        </Box>
      </ModalBox>
    </Modal>
  );
}

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 680px;
  background-color: white;
  padding: 16px;
  box-shadow: 24px;
  border-radius: 8px;
`;
