import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import he from 'he'; // Import the he library

export default function GoogleTrends(props) {
  const { title, news_title, picture } = props;
  const theme = useTheme();

  // Decode HTML entities
  const decodedTitle = he.decode(title);
  const decodedNewsTitle = he.decode(news_title);

  return (
    <Card sx={{ display: 'flex', width: '100%', margin: 1 }}>
      {' '}
      {/* Set a fixed width */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='h5'>
            {decodedTitle}
          </Typography>
          <Typography
            variant='subtitle1'
            color='text.secondary'
            component='div'
          >
            {decodedNewsTitle}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component='img'
        sx={{ width: 100 }}
        image={picture}
        alt='trend image'
      />
    </Card>
  );
}
