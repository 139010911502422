import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LinkIcon from '@mui/icons-material/Link';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import { CATEGORY_MAP, FORMAT_MAP } from '../../../../assets/data/LANGUAGE_MAP';
import { toCamelCase } from '../../../../hooks/textFormatter';
import { Box, Chip, Grid, Rating } from '@mui/material';
import { WorkspaceContext } from '../../../../contextApi/WorkspaceContext';
import formatTimestamp from '../../../../hooks/formatTimestamp';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ContentCard(props) {
  const [expanded, setExpanded] = useState(false);
  const { workspaceId, content } = props;
  const navigate = useNavigate();
  const { workspaceData, setWorkspaceData } = useContext(WorkspaceContext);

  const handleCreateClick = (e) => {
    // Handle card click event, e.g., navigate to another page
    e.preventDefault();
    setWorkspaceData((prev) => ({
      ...prev,
      selectedSource: content,
      selectedSourceType: 'article',
    }));
    navigate(`/myworkspace/${workspaceId}/create`);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(content.url, '_blank');
  };

  let keywords_str = String(content.keywords);
  const cleanedKeywordsStr = keywords_str.replace(/[{}"]/g, '');
  const keywords_lst = cleanedKeywordsStr.split(',').map((item) => item.trim());

  // Prefix each keyword with a '#' and join them with a space
  const formattedKeywords = keywords_lst
    .map((keyword) => `#${keyword}`)
    .join(' ');

  // You can now use `formattedKeywords` to display in your component

  const convertMetricToRating = (metric) => {
    const roundedMetric = Math.round(metric * 10) / 10; // Round to one decimal place
    return roundedMetric * 5; // Convert to a rating out of 5
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 500,
        width: '100%',
        height: 'auto',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CardMedia
          component='img'
          sx={{ width: 200, height: 140, margin: '20px', borderRadius: '8px' }}
          image={content.thumbnail_url}
          alt='Thumbnail'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: '1 1 auto',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'top',
            }}
          >
            <Typography
              fontSize='18px'
              variant='h6'
              color='black'
              width='100%'
              marginRight='10px'
            >
              {content.headline}
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
              sx={{ height: '40px' }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardContent>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: '1 1 auto',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body2' color='text.secondary'>
                  {content.source}
                </Typography>
                <IconButton onClick={handleLinkClick}>
                  <LinkIcon marginLeft='4px' color='grey' />
                </IconButton>
              </div>
              <Typography variant='body2' color='text.secondary'>
                {formatTimestamp(content.timestamp)}
              </Typography>
            </CardContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: '14px',
                alignItems: 'center',
              }}
            >
              <AvatarGroup>
                {content?.usernames?.map((username, idx) => {
                  <Tooltip key={idx} title={username} placement='top'>
                    <Avatar alt={username} />
                  </Tooltip>;
                })}
              </AvatarGroup>
              <CardActions>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleCreateClick}
                >
                  생성 시작
                </Button>
              </CardActions>
            </div>
          </div>
        </div>
      </div>

      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Box display='flex' gap={0.5} mb={2}>
            <Chip
              label={content.source}
              sx={{ backgroundColor: 'black', color: 'white' }}
            />
            <Chip
              label={
                CATEGORY_MAP[toCamelCase(content.category)] ?? content.category
              }
              sx={{ backgroundColor: '#0B8FFC', color: 'white' }}
            />
            <Chip
              label={FORMAT_MAP[content.format] ?? content.format}
              sx={{ backgroundColor: '#88909B', color: 'white' }}
            />
          </Box>

          <Box display='flex' alignItems='center' mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  display='block'
                  gutterBottom
                >
                  스코어
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    fontSize: '24px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                  }}
                >
                  {content.total_score ?? 'NA'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  display='block'
                  gutterBottom
                >
                  유사 기사수
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    fontSize: '24px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                  }}
                >
                  {content.sim_count ?? 'NA'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  display='block'
                  gutterBottom
                >
                  평균 트래픽
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    fontSize: '24px',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                  }}
                >
                  {content.sim_avg_views ?? 'NA'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Box display='flex' alignItems='center' mb={1}>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
                영향성
              </Typography>
              <Rating
                value={convertMetricToRating(content.influence) ?? 'NA'}
                readOnly
              />
            </Box>
            <Box display='flex' alignItems='center' mb={1}>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
                근접성
              </Typography>
              <Rating
                value={convertMetricToRating(content.proximity) ?? 'NA'}
                readOnly
              />
            </Box>
            <Box display='flex' alignItems='center' mb={1}>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
                흥미성
              </Typography>
              <Rating
                value={convertMetricToRating(content.influence) ?? 'NA'}
                readOnly
              />
            </Box>
            <Box display='flex' alignItems='center'>
              <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
                저명성
              </Typography>
              <Rating
                value={convertMetricToRating(content.eminence) ?? 'NA'}
                readOnly
              />
            </Box>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}
