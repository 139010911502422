import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function KeywordInput({
  inputValue,
  setInputValue,
  onClickHandler,
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClickHandler();
    }
  };

  return (
    <Box
      component='form'
      sx={{
        margin: '24px',
        width: 'auto',
      }}
      noValidate
      autoComplete='off'
    >
      <TextField
        id='outlined-basic'
        label='키워드 추가'
        variant='outlined'
        fullWidth
        onKeyDown={handleKeyDown}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Box>
  );
}
