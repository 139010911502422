import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const CustomButton = styled(Button)({
  position: 'relative',
  justifyContent: 'left',
  left: '10px',
  height: 'calc(1.8em + 22px)',
  width: '240px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '8px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#44505F',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: '#242B35',
    borderColor: '#242B35',
  },
  '&:fill': ({ selected, theme }) =>
    selected ? theme.neutralColor.dark3 : 'none',
});

export default function ButtonSimple({ idx, item, onClickHandler, isLocked }) {
  return (
    <CustomButton
      key={idx}
      variant='contained'
      startIcon={item.component}
      selected={item.selected}
      onClick={() => {
        if (!isLocked) {
          onClickHandler({ id: idx, value: item.value, path: item.path });
        }
      }}
      disableRipple
    >
      {item.value}
    </CustomButton>
  );
}
