import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import PopupModal from '../../../components/PopupModal';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Stack } from '@mui/system';
import { Chip } from '@mui/material';
import GoogleTrends from './components/GoogleTrends';

export default function Idea() {
  //여기 들어오면 workspaceData Clear 한번 해줘야할듯

  const { sessionJWT, getSession } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [trends, setTrends] = useState([]);
  const { workspaceId } = useParams();
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const navigate = useNavigate();

  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else if (confirmOption === 'goToSignIn') {
        return () => {
          navigate('/signin');
        };
      } else {
        return confirmOption;
      }
    };
    PopupModal.closePopupModal = () => {
      setPopupModalStatus(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setPopupModalStatus(true);
  };

  useEffect(() => {
    if (!sessionJWT) {
      getSession();
    } else return;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sessionJWT && workspaceId) {
      setIsLoading(true);
      const fetchData = async () => {
        const request = await fetch(
          `${APIs('sources', workspaceId)}/google-trends`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setTrends(data);
        } else return;
      };
      fetchData();
      setIsLoading(false);
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const CHIPS = [
    {
      name: 'Breaking',
      icon: <PriorityHighIcon fontSize='small' style={{ color: 'FF2869' }} />,
      path: 'breaking',
    },
    {
      name: 'Article',
      icon: <ArticleIcon fontSize='small' style={{ color: '0B8FFC' }} />,
      path: 'feed',
    },
    {
      name: 'Doc',
      icon: <PictureAsPdfIcon fontSize='small' style={{ color: '05ED94' }} />,
      path: 'create/doc',
    },
    {
      name: 'Text',
      icon: <TextFieldsIcon fontSize='small' style={{ color: '17BAED' }} />,
      path: 'create/text',
    },
  ];

  return (
    <>
      <Wrapper>
        <TitleSection>Get started with</TitleSection>
        <ChipSection>
          <Stack direction='row' spacing={1}>
            {CHIPS.map((item, idx) => (
              <Chip
                key={idx}
                icon={item.icon}
                label={item.name}
                variant='outlined'
                sx={{ padding: 0.8, fontSize: '16px' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/myworkspace/${workspaceId}/${item.path}`);
                }}
              />
            ))}
          </Stack>
        </ChipSection>
        <TitleSection>Google Trends</TitleSection>
        <TrendsContainer>
          {trends.map((item, idx) => (
            <GoogleTrends
              key={idx}
              title={item.title}
              news_title={item.news_title}
              picture={item.picture}
              published={item.published}
              traffic={item.traffic}
              summary={item.summary}
              news_url={item.news_url}
            />
          ))}
        </TrendsContainer>
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  padding: 20px 40px 80px 40px;
  overflow-y: auto;
`;

const TrendsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
`;

const OptionContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`;

const TitleSection = styled.div`
  font-size: 16px;
  font-family: 'Arial';
  color: ${({ theme }) => theme.pb.darkGray};
  margin-bottom: 14px;
  margin-top: 32px;
`;

const ChipSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  .MuiChip-root {
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: ${({ theme }) =>
        theme.pb.lightGray}; /* Change to your desired color */
    }
  }
`;
