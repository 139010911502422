import React from 'react';
import styled from 'styled-components';

const Form = ({ formInfo, input, handleInput, submitInput, isCorrect }) => {
  const { title, type } = formInfo;

  return (
    <Wrapper
      onSubmit={e => {
        e.preventDefault();
        submitInput(input, type);
      }}
    >
      <Text>{title}</Text>
      <Input
        name={type}
        onChange={handleInput}
        value={input}
        placeholder={
          type === 'workspace' ? 'Workspace Name' : 'Enter Access Code'
        }
        autoComplete="off"
      />
      {input && type === 'workspace' && (
        <Notice isCorrect={isCorrect}>
          {NOTICE[type][isCorrect ? 'correct' : 'incorrect']}
        </Notice>
      )}
      <Button
        isStatusOn={type === 'workspace' ? input : false}
        disabled={!(Boolean(input) && isCorrect)}
      >
        {type === 'workspace' ? 'Request Access' : 'Next'}
      </Button>
    </Wrapper>
  );
};

export default Form;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const Text = styled.p`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.pb.black};
  font-size: 16px;
  line-height: 19px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1.25rem;
  border: 0.5px solid #f5f5f5;
  border-radius: 20px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  font-size: 14px;
  line-height: 16px;
`;

const Button = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 400px;
  padding: 0.8rem 11.25rem;
  border-radius: 42px;
  transform: ${({ isStatusOn }) =>
    !isStatusOn ? 'translateY(4.75rem)' : 'translateY(6.2rem)'};
  transition: 0.5s;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.pb.lightGrey : theme.pb.midBlue};
  color: ${({ theme, disabled }) => (disabled ? theme.pb.grey : 'white')};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  white-space: nowrap;
  cursor: ${({ disabled }) => !disabled && 'pointer'};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.pb.darkBlue};
  }
`;

const Notice = styled.p`
  position: absolute;
  margin: 0.5rem 1.25rem;
  color: ${({ isCorrect, theme }) =>
    isCorrect ? theme.info.green : theme.notice};
  transform: translateY(4.5rem);
  font-size: 12px;
`;

const NOTICE = {
  workspace: {
    correct: 'Workspace found!',
    incorrect: 'Workspace not found',
  },
};
