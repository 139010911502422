import * as React from 'react';
import Chip from '@mui/material/Chip';

export default function LinkChip({ url, inputValues, setInputValues }) {
  const handleClick = () => {
    window.open(url);
  };

  const handleRemove = () => {
    setInputValues(inputValues.filter((val) => val !== url));
  };

  // Function to trim the label to a certain number of characters
  const trimLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + '...';
    }
    return label;
  };

  // Trimmed label with a maximum of 40 characters
  const trimmedLabel = trimLabel(url, 40);

  return (
    <Chip
      label={trimmedLabel}
      variant='outlined'
      onClick={handleClick}
      onDelete={handleRemove}
    />
  );
}
