import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';
import formatTimestamp from '../../../../hooks/formatTimestamp';

export default function RelatedContentCard(props) {
  const { content, category, isSelected, onCardClick } = props;
  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(content.url, '_blank');
  };

  const handleCardClick = (e) => {
    e.preventDefault();
    onCardClick(category, content);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 500,
        width: '100%',
        height: 'auto',
        border: isSelected ? `2px solid #0B8FFC` : 'none',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#C9CBCF', // Grey color on hover
        },
      }}
      onClick={handleCardClick}
      isSelected={isSelected}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CardMedia
          component='img'
          sx={{ width: 200, height: 140, margin: '20px', borderRadius: '8px' }}
          image={content.thumbnail_url}
          alt='Thumbnail'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: '1 1 auto',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'top',
            }}
          >
            <Typography
              fontSize='18px'
              variant='h6'
              color='black'
              width='100%'
              marginRight='10px'
            >
              {content.headline}
            </Typography>
          </CardContent>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: '1 1 auto',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body2' color='text.secondary'>
                  {content.source}
                </Typography>
                <IconButton onClick={handleLinkClick}>
                  <LinkIcon marginLeft='4px' color='grey' />
                </IconButton>
              </div>
              <Typography variant='body2' color='text.secondary'>
                {formatTimestamp(content.timestamp)}
              </Typography>
            </CardContent>
          </div>
        </div>
      </div>
    </Card>
  );
}
