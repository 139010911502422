import React from 'react';
import styled from 'styled-components';

const FormatCard = (props) => {
  const { id, img, title, content, isActivated, selected, onClick } = props;

  return (
    <>
      <Wrapper
        isActivated={isActivated}
        key={id}
        selected={selected}
        onClick={onClick}
      >
        <DataCardInfo>
          <TitleLogo>{img}</TitleLogo>
          <TitleText>{title}</TitleText>
          <ContentText>{content}</ContentText>
        </DataCardInfo>
      </Wrapper>
    </>
  );
};

export default FormatCard;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 8px;
  width: 280px;
  height: 220px;
  border-radius: 8px;
  border: ${({ selected }) => (selected ? '2px solid #0B8FFC' : 'none')};

  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  transition: 0.35s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.pb.darkerGray : theme.pb.chartGray};
  }
`;

const DataCardInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const TitleText = styled.p`
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: normal;
`;

const ContentText = styled.p`
  width: 170px;
  white-space: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #000f1f;
  margin-top: 8px;
  margin-bottom: 13px;
  color: #666666;
`;

const TitleLogo = styled.div`
  margin-bottom: 12px;
`;
