import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';

export default function CustomizedInputBase({
  inputValue,
  setInputValue,
  label,
  onClickHandler,
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClickHandler();
    }
  };

  return (
    <Paper
      component='form'
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        placeholder={label}
        inputProps={{ 'aria-label': { label } }}
        onKeyDown={handleKeyDown}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
      <IconButton
        color='primary'
        sx={{ p: '10px' }}
        aria-label='addIcon'
        onClick={onClickHandler}
      >
        <AddIcon />
      </IconButton>
    </Paper>
  );
}
