import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CATEGORY_MAP, FORMAT_MAP } from '../../../../assets/data/LANGUAGE_MAP';
import { toCamelCase } from '../../../../hooks/textFormatter';
import {
  Card,
  Avatar,
  AvatarGroup,
  Typography,
  Tooltip,
  CardContent,
  Box,
  IconButton,
  Grid,
  Rating,
  Chip,
  Button,
} from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { WorkspaceContext } from '../../../../contextApi/WorkspaceContext';

const StyledCard = styled(Card)`
  position: relative; /* Add position relative to make absolute positioning work */
`;

export default function NewsCard(props) {
  const { workspaceId, content } = props;
  const navigate = useNavigate();
  const { workspaceData, setWorkspaceData } = useContext(WorkspaceContext);

  const handleCreateClick = (e) => {
    // Handle card click event, e.g., navigate to another page
    e.preventDefault();
    setWorkspaceData((prev) => ({
      ...prev,
      selectedSource: content,
      selectedSourceType: 'article',
    }));
    navigate(`/myworkspace/${workspaceId}/create`);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(content.url, '_blank');
  };

  let keywords_str = String(content.keywords);
  const cleanedKeywordsStr = keywords_str.replace(/[{}"]/g, '');
  const keywords_lst = cleanedKeywordsStr.split(',').map((item) => item.trim());

  // Prefix each keyword with a '#' and join them with a space
  const formattedKeywords = keywords_lst
    .map((keyword) => `#${keyword}`)
    .join(' ');

  // You can now use `formattedKeywords` to display in your component

  const convertMetricToRating = (metric) => {
    const roundedMetric = Math.round(metric * 10) / 10; // Round to one decimal place
    return roundedMetric * 5; // Convert to a rating out of 5
  };

  return (
    <StyledCard sx={{ minWidth: 300, maxWidth: 360 }}>
      <CardContent>
        <Typography
          variant='h6'
          component='div'
          sx={{ fontWeight: 'bold', fontSize: '20px', mb: 1 }}
        >
          {content.headline}
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          {formattedKeywords}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 1,
          }}
        >
          <Box
            component='img'
            sx={{
              borderRadius: 1,
              width: 320,
              height: 190,
            }}
            alt='Card Image'
            src={content.thumbnail_url}
          />
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography variant='body2' color='text.secondary' component='span'>
            컨텐츠 정보
          </Typography>
          <IconButton aria-label='link' onClick={handleLinkClick}>
            <LinkIcon />
          </IconButton>
        </Box>
        <Box display='flex' gap={0.5} mb={2}>
          <Chip
            label={content.source}
            sx={{ backgroundColor: 'black', color: 'white' }}
          />
          <Chip
            label={
              CATEGORY_MAP[toCamelCase(content.category)] ?? content.category
            }
            sx={{ backgroundColor: '#0B8FFC', color: 'white' }}
          />
          <Chip
            label={FORMAT_MAP[content.format] ?? content.format}
            sx={{ backgroundColor: '#88909B', color: 'white' }}
          />
        </Box>

        <Box display='flex' alignItems='center' mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant='body2'
                color='text.secondary'
                display='block'
                gutterBottom
              >
                스코어
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '24px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                }}
              >
                {content.total_score ?? 'NA'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant='body2'
                color='text.secondary'
                display='block'
                gutterBottom
              >
                유사 기사수
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '24px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                }}
              >
                {content.sim_count ?? 'NA'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant='body2'
                color='text.secondary'
                display='block'
                gutterBottom
              >
                평균 트래픽
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '24px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                }}
              >
                {content.sim_avg_views ?? 'NA'}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mb={2}>
          <Box display='flex' alignItems='center' mb={1}>
            <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
              영향성
            </Typography>
            <Rating
              value={convertMetricToRating(content.influence) ?? 'NA'}
              readOnly
            />
          </Box>
          <Box display='flex' alignItems='center' mb={1}>
            <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
              근접성
            </Typography>
            <Rating
              value={convertMetricToRating(content.proximity) ?? 'NA'}
              readOnly
            />
          </Box>
          <Box display='flex' alignItems='center' mb={1}>
            <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
              흥미성
            </Typography>
            <Rating
              value={convertMetricToRating(content.influence) ?? 'NA'}
              readOnly
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography variant='body2' color='text.secondary' sx={{ mr: 2 }}>
              저명성
            </Typography>
            <Rating
              value={convertMetricToRating(content.eminence) ?? 'NA'}
              readOnly
            />
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <AvatarGroup>
            {content?.usernames?.map((username, idx) => {
              <Tooltip key={idx} title={username} placement='top'>
                <Avatar alt={username} />
              </Tooltip>;
            })}
          </AvatarGroup>
          <Button
            variant='contained'
            color='primary'
            onClick={handleCreateClick}
          >
            생성 시작
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
