import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CategorySelect from './CategorySelect';
import KeywordInput from './KeywordInput';
import KeywordChip from '../../../Preference/components/KeywordChip';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';

export default function MediaInfo(props) {
  const {
    sourceUrl,
    keywords,
    setKeywords,
    category,
    setCategory,
    thumbnail,
    setThumbnail,
  } = props;
  const [inputKeyword, setInputKeyword] = useState('');

  const handleAddKeyword = () => {
    if (inputKeyword.trim() !== '') {
      setKeywords([...keywords, inputKeyword]);
      setInputKeyword('');
    }
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(sourceUrl, '_blank');
  };

  return (
    <Box
      sx={{
        width: 'auto',
        height: 'auto',
        border: '0.5px solid grey',
        borderRadius: '4px',
      }}
    >
      {sourceUrl && (
        <SourceWrapper>
          <Title>아티클 소스 링크</Title>
          <IconButton onClick={handleLinkClick}>
            <LinkIcon marginLeft='4px' color='grey' />
          </IconButton>
        </SourceWrapper>
      )}
      {thumbnail && (
        <ThumbnailWrapper>
          <Title>썸네일 이미지</Title>
          <ThumbnailImage src={thumbnail} alt='Thumbnail' />
        </ThumbnailWrapper>
      )}
      <CategorySelect category={category} setCategory={setCategory} />
      <KeywordInput
        inputValue={inputKeyword}
        setInputValue={setInputKeyword}
        onClickHandler={handleAddKeyword}
      />
      <ChipSection>
        <Grid container spacing={1}>
          {keywords.map((keyword, index) => (
            <Grid item key={index}>
              <KeywordChip
                key={index}
                keyword={keyword}
                inputValues={keywords}
                setInputValues={setKeywords}
              />
            </Grid>
          ))}
        </Grid>
      </ChipSection>
    </Box>
  );
}

const SourceWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: left;
`;

const ThumbnailImage = styled.img`
  max-width: 360px;
  height: auto;
  border-radius: 4px;
  margin-top: 8px;
`;

const ChipSection = styled.div`
  width: 90%;
  margin: 24px;
`;
