import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const ScoreButton = styled(Button)(({ active }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: 'none',
  borderRadius: 40,
  borderColor: active ? blue[500] : '#88909B', // Change color when active
  fontFamily: 'Arial',
  color: active ? blue[500] : '#88909B', // Change color when active
  '&:hover': {
    backgroundColor: '#ECF0F2',
    borderColor: active ? blue[500] : '#88909B',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
  },
  '&:focus': {
    backgroundColor: '#ECF0F2',
    borderColor: active ? blue[500] : '#88909B',
  },
}));

export default function ScoreFilter({ scoreFilter, setScoreFilter }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [score, setScore] = useState('');

  // Initialize score state based on scoreFilter
  useEffect(() => {
    if (scoreFilter !== undefined) {
      setScore(scoreFilter);
    }
  }, [scoreFilter]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleScoreChange = (event) => {
    const newScore = event.target.value;
    setScore(newScore);
    setScoreFilter(newScore); // Update the scoreFilter state in the parent component
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <div>
      <ScoreButton
        variant='outlined'
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        active={score !== null} // Set active based on the presence of a score value
      >
        스코어
      </ScoreButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              component='form'
              sx={{
                marginTop: 1,
                border: 0.2,
                p: 1,
                bgcolor: 'background.paper',
                borderColor: '#88909B',
                borderRadius: 4,
                maxHeight: 180,
                overflowY: 'scroll',
              }}
            >
              <TextField
                label='Score'
                type='text'
                helperText='e.g. 2.8'
                onChange={handleScoreChange}
                value={score} // Use value instead of defaultValue
              />
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
