import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Typography, CardContent, Box, IconButton } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme, isSelected }) => ({
  position: 'relative',
  border: isSelected ? `2px solid ${theme.palette.primary.main}` : 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[200], // Grey color on hover
  },
}));

export default function RelatedCard({
  content,
  category,
  isSelected,
  onCardClick,
}) {
  const handleCardClick = (e) => {
    e.preventDefault();
    onCardClick(category, content);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(content.url, '_blank');
  };

  const keywords_str = String(content.keywords);
  const cleanedKeywordsStr = keywords_str.replace(/[{}"]/g, '');
  const keywords_lst = cleanedKeywordsStr.split(',').map((item) => item.trim());
  const formattedKeywords = keywords_lst
    .map((keyword) => `#${keyword}`)
    .join(' ');

  return (
    <StyledCard
      sx={{ minWidth: 360 }}
      onClick={handleCardClick}
      isSelected={isSelected}
    >
      <CardContent>
        <Typography
          variant='h6'
          component='div'
          sx={{ fontWeight: 'bold', fontSize: '20px', mb: 1 }}
        >
          {content.headline}
        </Typography>
        <Typography variant='body2' sx={{ mb: 1 }}>
          {formattedKeywords}
        </Typography>
        <Box display='flex' alignItems='center'>
          <Typography variant='body2' color='text.secondary' component='span'>
            컨텐츠 정보
          </Typography>
          <IconButton aria-label='link' onClick={handleLinkClick}>
            <LinkIcon />
          </IconButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
