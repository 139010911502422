import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SearchBar from './components/SearchBar';
import NewsCard from './components/NewsCard';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import LoadingPageFull from '../../../components/LoadingPageFull';
import ContentCard from './components/ContentCard';
import LoadingButton from '@mui/lab/LoadingButton';
import SourceFilter from './components/SourceFilter';
import CategoryFilter from './components/CategoryFilter';
import ScoreFilter from './components/ScoreFilter';
import ApplyButton from './components/ApplyButton';
import PopupModal from '../../../components/PopupModal';

export default function Feed() {
  const { sessionJWT, getSession } = useContext(AccountContext);
  const { workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sources, setSources] = useState([]);
  const [sourceFilters, setSourceFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [scoreFilter, setScoreFilter] = useState(null);
  const [queryFilter, setQueryFilter] = useState(null);
  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionJWT) {
      getSession();
    } else {
      fetchFiltersAndData();
    }
  }, [sessionJWT, workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchFiltersAndData = async () => {
    if (sessionJWT && workspaceId) {
      await fetchFilter();
    }
  };

  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else if (confirmOption === 'goToSignIn') {
        return () => {
          navigate('/signin');
        };
      } else {
        return confirmOption;
      }
    };
    PopupModal.closePopupModal = () => {
      setPopupModalStatus(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Panomix.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setPopupModalStatus(true);
  };

  const fetchFilter = async () => {
    const request = await fetch(`${APIs('userFilter', workspaceId)}`, {
      headers: { Authorization: `Bearer ${sessionJWT}` },
    });
    const data = await request.json();

    const sourceFilters = [];
    const categoryFilters = [];
    let scoreFilter = null;

    data.forEach((filter) => {
      switch (filter.filter_type) {
        case 'source':
          sourceFilters.push(filter.value);
          break;
        case 'category':
          categoryFilters.push(filter.value);
          break;
        case 'score':
          scoreFilter = filter.value;
          break;
        default:
          break;
      }
    });

    setSourceFilters(sourceFilters);
    setCategoryFilters(categoryFilters);
    setScoreFilter(scoreFilter);

    fetchData(sourceFilters, categoryFilters, scoreFilter);
  };

  const fetchData = async (sourceFilters, categoryFilters, scoreFilter) => {
    setIsLoading(true);

    let filterParams = '';

    if (sourceFilters.length > 0) {
      filterParams += `source=${sourceFilters.join(',')}&`;
    }
    if (categoryFilters.length > 0) {
      filterParams += `category=${categoryFilters.join(',')}&`;
    }
    if (scoreFilter) {
      filterParams += `score=${scoreFilter}&`;
    }

    filterParams = filterParams.slice(0, -1); // Remove the trailing '&'

    const request = await fetch(
      `${APIs('sources', workspaceId)}?${filterParams}`,
      {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      }
    );

    const data = await request.json();
    if (data.length > 0) {
      setSources(data);
    } else {
      setSources([]);
    }
    setIsLoading(false);
  };

  const fetchQueryData = async () => {
    setIsLoading(true);
    const request = await fetch(
      `${APIs('sources', workspaceId)}?query=${queryFilter}`,
      {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      }
    );

    const data = await request.json();
    if (data.length > 0) {
      setSources(data);
    } else {
      setSources([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!sessionJWT) {
      getSession();
    } else {
      fetchQueryData();
    }
  }, [queryFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetFilters = () => {
    setSourceFilters([]);
    setCategoryFilters([]);
    setScoreFilter(null);
    fetchData([], [], null);
  };

  const handleApplyFilters = () => {
    fetchData(sourceFilters, categoryFilters, scoreFilter);
  };

  const handleSetAsDefault = async () => {
    const filtersToSave = [
      ...sourceFilters.map((filter) => ({
        filter_type: 'source',
        expression: '=',
        value: filter,
      })),
      ...categoryFilters.map((filter) => ({
        filter_type: 'category',
        expression: '=',
        value: filter,
      })),
    ];

    if (scoreFilter) {
      filtersToSave.push({
        filter_type: 'score',
        expression: '=',
        value: scoreFilter,
      });
    }

    await fetch(`${APIs('userFilter', workspaceId)}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionJWT}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filtersToSave),
    });
    openCautionPopup({
      message: 'Saved as default',
      confirmOption: 'stay',
    });
  };

  return (
    <>
      {isLoading ? (
        <LoadingPageFull />
      ) : (
        <Wrapper>
          <SearchBar
            sessionJWT={sessionJWT}
            workspaceId={workspaceId}
            setQueryFilter={setQueryFilter} // Update if needed
          />
          <FilterContainer>
            <Stack direction='row' spacing={1} marginTop={1}>
              <SourceFilter
                sourceFilters={sourceFilters}
                setSourceFilters={setSourceFilters}
              />
              <CategoryFilter
                categoryFilters={categoryFilters}
                setCategoryFilters={setCategoryFilters}
              />
              <ScoreFilter
                scoreFilter={scoreFilter}
                setScoreFilter={setScoreFilter}
              />
            </Stack>
            <Stack direction='row' spacing={1} marginTop={1}>
              <TextButton isActive onClick={handleResetFilters}>
                초기화
              </TextButton>
              <ApplyButton
                active
                name='적용'
                onClickHandler={handleApplyFilters}
              />
              <ApplyButton
                active
                name='필터 저장'
                onClickHandler={handleSetAsDefault}
              />
            </Stack>
          </FilterContainer>
          <NewsCardContainer>
            {sources.map((item, idx) => (
              <ContentCard key={idx} workspaceId={workspaceId} content={item} />
            ))}
          </NewsCardContainer>
          {popupModalStatus && (
            <PopupModal
              popupModalStatus={popupModalStatus}
              closePopupModal={PopupModal.closePopupModal}
              popupMessage={PopupModal.popupMessage}
              handleConfirmAction={PopupModal.handleConfirmAction}
            />
          )}
        </Wrapper>
      )}
    </>
  );
}

// Styled Components
const Wrapper = styled.div`
  justify-content: center;
  position: relative;
  height: 100vh;
  padding: 20px 40px 80px 40px;
  ${({ theme }) => theme.animation.fadeInBottom};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
`;

const NewsCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(580px, 1fr));
  grid-gap: 10px;
  margin-top: 14px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial';
  font-size: 14px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? 'black' : '#88909B')};
  cursor: pointer;
`;
