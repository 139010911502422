import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function MediaImages(props) {
  const { images, setThumbnail } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, item) => {
    // setAnchorEl(event.currentTarget);
    setThumbnail(item.media_url);
  };

  return (
    <Box
      sx={{
        width: 'auto',
        height: 'auto',
        marginTop: '14px',
        marginBottom: '48px',
      }}
    >
      <ImageList variant='masonry' cols={1} gap={8}>
        {images &&
          images.map((item, idx) => (
            <ImageListItem key={idx}>
              <ImageListItemBar
                position='top'
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    onClick={(e) => handleClick(e, item)}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
              <img
                srcSet={`${item.media_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.media_url}?w=248&fit=crop&auto=format`}
                alt={item.caption}
                loading='lazy'
              />
              <ImageListItemBar
                position='below'
                subtitle={item.caption}
                sx={{
                  '& .MuiImageListItemBar-subtitle': {
                    display: 'block',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            </ImageListItem>
          ))}
      </ImageList>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClick}>Set as Thumbnail</MenuItem>
      </Menu>
    </Box>
  );
}
