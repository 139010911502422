import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import PopupModal from '../../../components/PopupModal';
import DateSelect from '../../../components/InputComponents/DateSelect';
import DeltaCard from './components/DeltaCard';
import { DASHBOARD_DATA } from './MOCK_DATA/DASHBOARD_DATA';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { styled as MuiStyled } from '@mui/material/styles';

import { changeDateFormatForSubmit } from '../../../hooks/changeDateFormat';

import { ReactComponent as ArrowWhite } from './components/arrowWhite.svg';
import { ReactComponent as CircleWhite } from './components/circleWhite.svg';
import { ReactComponent as ClockWhite } from './components/clockWhite.svg';
import { ReactComponent as ReplyWhite } from './components/replyWhite.svg';
import { ReactComponent as Trash } from './components/trash.svg';

import { WorkspaceContext } from '../../../contextApi/WorkspaceContext';
import { AccountContext } from '../../../contextApi/AccountContext';
import { APIs } from '../../../config';
import LoadingPageFull from '../../../components/LoadingPageFull';
import { STATUS_MAP } from '../../../assets/data/LANGUAGE_MAP';

const actions = [
  {
    text: '심의 요청',
    status: 'inreview',
    icon: <ArrowWhite />,
  },
  {
    text: '심의 완료',
    status: 'reviewcomplete',
    icon: <CircleWhite />,
  },
  {
    text: '반려',
    status: 'rejected',
    icon: <ReplyWhite />,
  },
  {
    text: '송고 예약',
    status: 'scheduled',
    icon: <ClockWhite />,
  },
];

export default function Dashboard() {
  const { sessionJWT } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [popupModalStatus, setPopupModalStatus] = useState(false);

  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'headline', headerName: '헤드라인', flex: 5 },
    { field: 'category', headerName: '카테고리', flex: 1.5 },
    {
      field: 'status',
      headerName: '상태',
      flex: 1,
      valueGetter: (params) => STATUS_MAP[params.row.status],
    },
    {
      field: 'username',
      headerName: '작성자',
      flex: 2,
      valueGetter: (params) => params.row.user.username,
    },
    {
      field: 'action',
      headerName: '액션',
      sortable: false,
      renderCell: (params) => {
        const onClickEdit = (e) => {
          e.stopPropagation(); // Prevent row selection
          const articleId = params.row.id; // Extract articleId from row data
          navigate(`/myworkspace/${workspaceId}/draft/${articleId}`);
        };

        return <Button onClick={onClickEdit}>수정</Button>;
      },
      flex: 1,
    },
  ];
  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else if (confirmOption === 'goToSignIn') {
        return () => {
          navigate('/signin');
        };
      } else {
        return confirmOption;
      }
    };
    PopupModal.closePopupModal = () => {
      setPopupModalStatus(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Panomix.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setPopupModalStatus(true);
  };

  const handleAction = (action) => {
    fetch(`${APIs('articles', workspaceId)}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionJWT}`,
      },
      body: JSON.stringify({ article_ids: selectionModel, status: action }),
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload(true);
        } else {
          return response.json().then((data) => {
            openCautionPopup({
              message: data.detail,
              confirmOption: 'refresh',
            });
          });
        }
      })
      .catch((error) => {
        openCautionPopup({
          message: 'Something went wrong. Please contact Panomix.',
          confirmOption: 'refresh',
        });
      });
  };
  const handleDelete = () => {
    fetch(`${APIs('articles', workspaceId)}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionJWT}`,
      },
      body: JSON.stringify({ article_ids: selectionModel }),
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload(true);
        } else {
          openCautionPopup({
            message: response.detail,
            confirmOption: 'refresh',
          });
        }
      })
      .catch((error) => {
        openCautionPopup({
          message: 'Something went wrong. Please contact Panomix.',
          confirmOption: 'refresh',
        });
      });
  };

  let ONE_WEEK_AGO = new Date();
  ONE_WEEK_AGO.setDate(ONE_WEEK_AGO.getDate() - 3);

  const [selectedPeriod, setSelectedPeriod] = useState([
    ONE_WEEK_AGO,
    new Date(),
  ]);

  useEffect(() => {
    if (sessionJWT && workspaceId && selectedPeriod.length === 2) {
      setIsLoading(true);
      const fetchData = async () => {
        const request = await fetch(
          `${APIs('articles', workspaceId)}?startDate=${changeDateFormatForSubmit(selectedPeriod[0])}&endDate=${changeDateFormatForSubmit(selectedPeriod[1])}`,
          {
            headers: { Authorization: `Bearer ${sessionJWT}` },
          }
        );
        const data = await request.json();
        if (data.length > 0) {
          setArticles(data);
        } else return;
      };
      fetchData();
      setIsLoading(false);
    }
  }, [sessionJWT, workspaceId, selectedPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Calculate status counts when data changes
    const counts = articles.reduce((acc, item) => {
      const { status } = item;
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});
    setStatusCounts(counts);
  }, [articles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading ? (
        <LoadingPageFull />
      ) : (
        <Wrapper>
          <DateSelectWrapper>
            <DateSelect
              dateRange={selectedPeriod}
              setDateRange={setSelectedPeriod}
              type='dashboard'
            />
          </DateSelectWrapper>
          <CardArea>
            <DeltaCard
              title='생성중'
              value={statusCounts.generating ? statusCounts.generating : 'NA'}
              icon='inWriting'
            />
            <DeltaCard
              title='작성중'
              value={statusCounts.indraft ? statusCounts.indraft : 'NA'}
              icon='inWriting'
            />
            <DeltaCard
              title='심의 요청'
              value={statusCounts.inreview ? statusCounts.inreview : 'NA'}
              icon='inReview'
            />
            <DeltaCard
              title='반려'
              value={statusCounts.rejected ? statusCounts.rejected : 'NA'}
              icon='returned'
            />
            <DeltaCard
              title='송고 예약'
              value={statusCounts.scheduled ? statusCounts.scheduled : 'NA'}
              icon='scheduled'
            />
            <DeltaCard
              title='송고 완료'
              value={statusCounts.delivered ? statusCounts.delivered : 'NA'}
              icon='published'
            />
          </CardArea>
          <ActionArea>
            {actions.map((item, index) => (
              <CustomButton
                key={index}
                variant='contained'
                startIcon={item.icon}
                disableRipple
                onClick={() => handleAction(item.status)}
              >
                {item.text}
              </CustomButton>
            ))}
            <DeleteButton
              variant='contained'
              startIcon={<Trash />}
              disableRipple
              onClick={handleDelete}
            >
              삭제
            </DeleteButton>
          </ActionArea>
          <DataArea>
            <DataGrid
              style={{ display: 'grid', gridTemplateRows: 'auto 1f auto' }}
              rows={articles}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              pageSizeOptions={[10, 50, 100, 300]}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              disableRowSelectionOnClick
            />
          </DataArea>
        </Wrapper>
      )}
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 84px;
  height: 100vh;
  width: 100%;
`;

const DateSelectWrapper = styled.div`
  position: fixed;
  width: 230px;
  right: 20px;
  top: 20px;
  width: 230px;
  z-index: 2;
`;

const CardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2px;
  margin-left: 20px;
  margin-right: 20px;
`;

const ActionArea = styled.div`
  display: flex;
  margin: 64px 20px 18px 20px;
  gap: 4px;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const DataArea = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const CustomButton = MuiStyled(Button)({
  height: '40px',
  width: '140px',
  boxShadow: '0px 1px 3px rgba(9, 16, 55, 0.4);',
  textTransform: 'none',
  fontSize: 14,
  padding: '6px 12px',
  borderRadius: '4px',
  backgroundColor: '#1A1F27',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#44505F',
  },
  cursor: 'pointer',
});

const DeleteButton = MuiStyled(Button)({
  height: '40px',
  width: '100px',
  boxShadow: '0px 1px 3px rgba(9, 16, 55, 0.4);',
  textTransform: 'none',
  fontSize: 14,
  borderRadius: '4px',
  backgroundColor: '#44505F',
  lineHeight: 1.5,
  fontFamily: 'Arial',
  outline: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: '#242B35',
  },
  cursor: 'pointer',
});
