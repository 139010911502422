import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main';
import Preference from './pages/Preference/Preference';
import Workspace from './pages/Workspace/Workspace';
import SelectWorkspace from './pages/WorkspaceList/SelectWorkspace/SelectWorkspace';
import CreateWorkspace from './pages/WorkspaceList/CreateWorkspace/CreateWorkspace';
import ApproveJoinWorkspace from './pages/WorkspaceList/ApproveJoinWorkspace/ApproveJoinWorkspace';
import AccessCode from './pages/AccessCode/AccessCode';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='*' element={<Main />} />
      <Route path='terms' element={<Main type='terms' />} />
      <Route path='cookies' element={<Main type='cookies' />} />
      <Route path='privacy' element={<Main type='privacy' />} />

      <Route path='/workspacelist' element={<SelectWorkspace />} />
      <Route path='/accesscode' element={<AccessCode />} />
      <Route path='/createworkspace' element={<CreateWorkspace />} />
      <Route path='/confirm/:token' element={<ApproveJoinWorkspace />} />
      <Route path='/preference' element={<Preference />} />
      <Route path='myworkspace/:workspaceId/*' element={<Workspace />} />
    </Routes>
  );
}
