import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditRuleSetModal from './EditRuleSetModal';

export default function RuleSetCard({
  id,
  name,
  description,
  rules,
  shared,
  availableRules,
  onEdit,
  onDelete,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
    handleMenuClose();
  };

  const handleDelete = (id) => {
    onDelete(id);
    handleMenuClose();
  };

  return (
    <>
      <StyledCard>
        <CardContent>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              <Typography variant='h5'>{name}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {description}
              </Typography>
            </Box>
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </CardContent>
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEdit}>변경</MenuItem>
          <MenuItem onClick={() => handleDelete(id)}>삭제</MenuItem>
        </Menu>
      </StyledCard>
      <EditRuleSetModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onEdit={onEdit}
        ruleset={{ id, name, description, rules, shared }}
        availableRules={availableRules}
      />
    </>
  );
}

const StyledCard = styled(Card)`
  max-width: 360px;
  min-width: 300px;
  flex: 1 1 360px; // Ensures that the cards are flexible but do not exceed 300px
`;
