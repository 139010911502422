import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TrendingDown } from './TrendingDown.svg';
import { ReactComponent as TrendingUp } from './TrendingUp.svg';
import { ReactComponent as PencilAlt } from './pencilAlt.svg';
import { ReactComponent as Globe } from './globe.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as CheckCircle } from './checkCircle.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Reply } from './reply.svg';

export default function DeltaCard(props) {
  const { title, value, icon } = props;
  const current =
    value !== undefined && value !== null
      ? value.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : 'NA';

  const icons = {
    inWriting: <PencilAlt />,
    inReview: <Arrow />,
    reviewComplete: <CheckCircle />,
    returned: <Reply />,
    scheduled: <Clock />,
    published: <Globe />,
  };

  return (
    <CardWrapper>
      <TotalElement>
        <LabelArea>
          {icons[icon]}
          <TotalLabel>{title}</TotalLabel>
        </LabelArea>

        <TotalNumber>{current}</TotalNumber>
      </TotalElement>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin: 2px;
  ${({ theme }) => theme.animation.fadeInBottom}
`;
const TotalElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 18px 24px 18px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
`;

const TotalNumber = styled.span`
  margin-top: 14px;
  color: ${({ theme }) => theme.neutralColor.dark2};
  font-size: 32px;
  line-height: 28px;
  font-weight: 500;
  font-family: 'Arial';
`;
const CompareArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  gap: 6px;
`;
const PercentArea = styled.span`
  font-size: 16px;
  font-family: 'Arial'
  line-height: 18px;
  font-weight: 500;
  color: ${({ diffColor }) => diffColor};
`;

const CompareText = styled.span`
  font-family: 'Arial';
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralColor.dark0};
`;

const LabelArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TotalLabel = styled.span`
  color: ${({ theme }) => theme.neutralColor.dark2};
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Arial';
`;
