import React, { useState, useEffect } from 'react';
import { COUNTRY_DATA } from '../../../../assets/data/COUNTRY_DATA';
import Select from 'react-select';
import { customStyles } from '../../../../styles/ReactSelectStyle';
import styled from 'styled-components';

export default function CurrencySelector({
  defaultCurrency,
  updatedCurrency,
  setCurrency,
}) {
  const [defaultValue, setDefaultValue] = useState({});
  const CURRENCY_LIST = [
    ...new Set(COUNTRY_DATA.map((item) => item.currencyCode)),
  ].filter((item) => item !== '');

  const options = CURRENCY_LIST.map((currency) => ({
    value: currency,
    label: currency,
  }));

  useEffect(() => {
    if (defaultCurrency) {
      setDefaultValue(options.find((item) => item.value === defaultCurrency));
    } else return;
  }, [defaultCurrency]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Section>
      <Select
        options={options}
        value={updatedCurrency || defaultValue}
        onChange={setCurrency}
        styles={{
          ...customStyles,
          control: (base) => ({
            ...base,
            width: '100%',
            minWidth: '170px',
            padding: '0px 6px',
            margin: '5px 0',
            border: '0.5px solid #F5F5F5',
            boxShadow: '0px 1px 3px rgba(9, 16, 55, 0.4)',
            borderRadius: '25px',
            background: '#fff',
            cursor: 'pointer',
          }),
        }}
      />
    </Section>
  );
}

const Section = styled.section`
  margin-right: 10px;
`;
