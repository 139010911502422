import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../contextApi/AccountContext';
import { APIs } from '../../config';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as BookOpenSVG } from '../../assets/icons/bookOpen.svg';
import { ReactComponent as HomeSVG } from '../../assets/icons/home.svg';
import { ReactComponent as CogSVG } from '../../assets/icons/cog.svg';
import { ReactComponent as TemplateSVG } from '../../assets/icons/template.svg';
import { ReactComponent as LogoutSVG } from '../../assets/icons/Logout.svg';
import { ReactComponent as IdeaSVG } from '../../assets/icons/sparkles.svg';
import RuleIcon from '@mui/icons-material/Rule';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import SelectSimple from '../PlaybookSelects';
import ButtonSimple, { CustomButton } from '../PlaybookButtons';

export default function WorkspaceMenu() {
  let { workspaceId, menu } = useParams();
  const navigate = useNavigate();

  const { sessionJWT, handleLogout } = useContext(AccountContext);
  const [workspaceDatas, setWorkspaceDatas] = useState([]);

  async function getWorkspaces() {
    try {
      const request = await fetch(`${APIs('workspaces')}`, {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      });
      const response = await request.json();

      if (request.ok) {
        const updatedWorkspaces = response.workspaces.map((item) => {
          return String(item.id) === String(workspaceId)
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false };
        });
        setWorkspaceDatas(updatedWorkspaces);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (sessionJWT) {
      getWorkspaces();
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectWorkspace = (selectedWorkspaceId) => {
    setWorkspaceDatas((prev) =>
      prev.map((data) => {
        return data.id === selectedWorkspaceId
          ? { ...data, isSelected: true }
          : { ...data, isSelected: false };
      })
    );
    navigate(`/myworkspace/${selectedWorkspaceId}/dashboard`);
  };

  const ICONS = [
    {
      value: '대시보드',
      component: <HomeSVG />,
      path: { type: 'playbook', url: 'dashboard' },
    },
    {
      value: '아이디어',
      component: <IdeaSVG />,
      path: { type: 'playbook', url: 'idea' },
    },
    {
      value: '아티클 피드',
      component: <TemplateSVG />,
      path: { type: 'playbook', url: 'feed' },
      selected: false,
    },
    {
      value: '마이 아티클',
      component: <BookOpenSVG />,
      path: { type: 'playbook', url: 'myarticle' },
      selected: false,
    },
    {
      value: '규칙',
      component: <RuleIcon />,
      path: { type: 'playbook', url: 'rule' },
      selected: false,
    },
    {
      value: '규칙 세트',
      component: <RuleFolderIcon />,
      path: { type: 'playbook', url: 'ruleset' },
      selected: false,
    },
    {
      value: '관리 및 설정',
      component: <CogSVG />,
      path: { type: 'playbook', url: 'setting' },
      selected: false,
    },
  ];
  const [iconInfo, setIconInfo] = useState(ICONS);

  useEffect(() => {
    setIconInfo((prev) =>
      prev.map((icon) => {
        return menu === icon.path.url
          ? { ...icon, selected: true }
          : { ...icon, selected: false };
      })
    );
  }, [window.location, menu]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickHandler = ({ id, value, path }) => {
    if (value === 'Logout') {
      signOut();
    } else {
      if (path.type === 'playbook') {
        navigate(`/myworkspace/${workspaceId}/${path.url}`);
      } else {
        window.open(path.url, '_blank');
      }
      setIconInfo((prev) =>
        prev.map((icon) => {
          return id === icon.id
            ? { ...icon, selected: true }
            : { ...icon, selected: false };
        })
      );
    }
  };

  const signOut = () => {
    handleLogout();
    localStorage.removeItem('idToken');
    navigate('/signin');
  };

  return (
    <MenuAreaWrapper>
      <SelectSimple
        defaultValue={Number(workspaceId)}
        options={workspaceDatas}
        onClickHandler={selectWorkspace}
      ></SelectSimple>
      <MenuOptionWrapper>
        {iconInfo.slice(0, 6).map((icon, idx) => (
          <ButtonSimple
            key={idx}
            item={icon}
            onClickHandler={onClickHandler}
            isLocked={false}
          ></ButtonSimple>
        ))}
      </MenuOptionWrapper>

      <SettingWrapper>
        {iconInfo.slice(6).map((icon, idx) => (
          <ButtonSimple
            key={idx}
            item={icon}
            onClickHandler={onClickHandler}
          ></ButtonSimple>
        ))}
        <CustomButton
          key='logout'
          variant='contained'
          onClick={() => signOut()}
          startIcon={<LogoutSVG />}
        >
          Logout
        </CustomButton>
      </SettingWrapper>
    </MenuAreaWrapper>
  );
}

const MenuAreaWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 260px;

  background-color: ${({ theme }) => theme.neutralColor.dark3};
  align-items: center;
`;

const MenuOptionWrapper = styled.div`
  position: fixed;
  top: 80px;
  width: 260px;
  justify-content: center;
`;

const SettingWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  width: 260px;
  align-items: center;
`;
