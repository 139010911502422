export const SOURCES_DATA = [
  '국민일보',
  '동아일보',
  '중앙일보',
  '스타투데이',
  '스포츠조선',
  '아시아경제',
  '연합뉴스',
  '여행스케치',
  '베이비타임즈',
  '헬스경향',
  '헬스조선',
  '오토헤럴드',
  '코메디닷컴',
  '데일리안',
  '글로벌이코노믹',
  '푸드투데이',
  '풋볼리스트',
  '디지털타임스',
  'Upworthy',
  'APNews',
  '한국경제',
  '여행신문',
  '월간산',
  '지디넷코리아',
  '트레블바이크',
  '파이낸셜뉴스',
  '인벤',
  '골닷컴',
  '블로터',
  '트래비',
  '경향신문',
  '데일리카',
  '디스패치',
  '문화일보',
  '서울경제',
  '스타패션',
  '엑스포츠',
  '뉴스1',
  '아이뉴스24',
  '9to5mac',
  'Al Jazeera',
  'BBC',
  'BoredPanda',
  'CNN',
  'Daily Mail',
  'Eater',
  'EliteDaily',
  'FashionPress.jp',
  'Gizmodo.jp',
  'HuffPost',
  'Mashable',
  '머니s',
  'SamMobile',
];

export const CATEGORIES_DATA = [
  'breaking news',
  'culture',
  'economy',
  'education',
  'entertainment',
  'environment',
  'general',
  'health',
  'history',
  'military',
  'politics',
  'science',
  'sports',
  'technology',
  'travel',
];
