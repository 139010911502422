import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../../contextApi/AccountContext';
import { useNavigate } from 'react-router';
import { APIs } from '../../../config';
import Header from '../../../components/Header';
import Form from './Form';
import PopupModal from '../../../components/PopupModal';
import RequestAccess from './RequestAccess';
import styled from 'styled-components';

export default function CreateWorkspace() {
  const { sessionJWT, getSession } = useContext(AccountContext);
  const [hasNoWorkspace, setHasNoWorkspace] = useState(false);

  useEffect(() => {
    if (!sessionJWT) {
      getSession().then(() => {});
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  const navigate = useNavigate();

  const [popupModalStatus, setPopupModalStatus] = useState(false);
  const [inputData, setInputData] = useState({
    create: {
      val: '',
      isCorrect: false,
    },
    join: { val: '', isCorrect: false },
    typingTimeout: 0,
  });
  const [isReadyToSubmit, setIsReadyToSubmit] = useState({
    create: true,
    join: true,
  });

  const openCautionPopup = ({ message, confirmOption, buttonName }) => {
    const confirmAction = () => {
      if (confirmOption === 'stay') {
        return () => {
          setPopupModalStatus(false);
        };
      } else if (confirmOption === 'refresh') {
        return () => {
          window.location.reload(true);
        };
      } else if (confirmOption === 'goToSignIn') {
        return () => {
          navigate('/signin');
        };
      } else {
        return confirmOption;
      }
    };

    PopupModal.closePopupModal = () => {
      setPopupModalStatus(false);
    };
    PopupModal.popupMessage =
      message || 'Sorry, something went wrong. Please contact Playbook.';
    PopupModal.confirmBtnName = buttonName || 'Confirm';
    PopupModal.handleConfirmAction = confirmAction();

    setPopupModalStatus(true);
  };

  const submitInput = async (input, type) => {
    if (type === 'create') {
      const request = await fetch(`${APIs('workspaces')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionJWT}`,
        },
        method: 'POST',
        body: JSON.stringify({
          name: input,
        }),
      });

      const response = await request.json();
      if (request.ok) {
        navigate(`/myworkspace/${response.id}/dashboard`, { replace: true });
      } else {
        openCautionPopup({
          message: response.detail,
          confirmOption: 'refresh',
        });
      }
    } else if (type === 'join') {
      const request = await fetch(
        `${APIs('workspace', inputData.join.workspaceId)}/join-request`,
        {
          headers: { Authorization: `Bearer ${sessionJWT}` },
          method: 'POST',
        }
      );

      const response = await request.json();
      if (response.detail === 'successfully requested') {
        setInputData((prev) => ({
          ...prev,
          join: {
            ...prev.join,
            goToRequest: true,
          },
        }));
      } else if (request.status !== 500) {
        openCautionPopup({
          message: response.detail,
          confirmOption: 'refresh',
        });
      } else {
        openCautionPopup({
          message: response?.detail,
          confirmOption: 'goToSignIn',
        });
      }
    }
  };

  const getWorkspaceData = async () => {
    try {
      const request = await fetch(`${APIs('workspaces')}`, {
        headers: { Authorization: `Bearer ${sessionJWT}` },
      });
      const response = await request.json();
      if (request.ok) {
        setHasNoWorkspace(
          response?.workspaces?.length + response?.invitedWorkspaces?.length ===
            0
        );
      } else if (request.status !== 500) {
        openCautionPopup({ message: response.detail, confirmOption: 'stay' });
      } else {
        openCautionPopup({
          message: response.detail,
          confirmOption: 'goToSignIn',
        });
      }
    } catch (err) {
      console.error(err);
      openCautionPopup({
        message: err,
        confirmOption: 'goToSignIn',
      });
    }
  };

  const findWorkspace = async (value, name) => {
    const request = await fetch(`${APIs('search')}?q=${value}`, {
      headers: {
        Authorization: `Bearer ${sessionJWT}`,
      },
      method: 'GET',
    });
    const response = request.json();
    const data = await response;

    if (request.status === 404) {
      setInputData((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          isCorrect: false,
        },
      }));
    } else if (request.ok) {
      setInputData((prev) => ({
        ...prev,
        [name]: {
          val: value,
          isCorrect: true,
          workspaceId: data.id,
        },
      }));
    } else return;
  };

  const handleInput = (type, input) => {
    const { name, value } = input.target;
    if (value?.length >= 3) {
      setIsReadyToSubmit((prev) => ({ ...prev, [type]: true }));
      findWorkspace(value, name);
    } else if (value?.length > 0 && value?.length < 3) {
      setIsReadyToSubmit((prev) => ({ ...prev, [type]: false }));
    }
  };

  useEffect(() => {
    if (sessionJWT) {
      getWorkspaceData();
    } else return;
  }, [sessionJWT]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Wrapper>
        {inputData?.join?.goToRequest ? (
          <RequestAccess
            workspaceName={inputData.join?.val}
            isRequestSucceed={inputData.join.goToRequest}
          />
        ) : (
          <AlignCenter>
            <Header>{HEADER_TEXT.workspace}</Header>
            <Form
              formInfo={FORM_DATA.create}
              input={inputData.create.val}
              setInputData={setInputData}
              handleInput={handleInput}
              submitInput={submitInput}
              status={inputData.create.isCorrect}
              isReadyToSubmit={isReadyToSubmit.create}
            />
            <Text>Or</Text>
            <Form
              formInfo={FORM_DATA.join}
              input={inputData.join.val}
              setInputData={setInputData}
              handleInput={handleInput}
              submitInput={submitInput}
              status={!inputData.join.isCorrect}
              isReadyToSubmit={isReadyToSubmit.join}
            />

            <TextButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(hasNoWorkspace ? '/signin' : '/workspacelist');
              }}
            >
              {hasNoWorkspace ? 'Log out' : 'Go back to workspace list page'}
            </TextButton>
          </AlignCenter>
        )}
      </Wrapper>
      <PopupModal
        popupModalStatus={popupModalStatus}
        closePopupModal={PopupModal.closePopupModal}
        popupMessage={PopupModal.popupMessage}
        handleConfirmAction={PopupModal.handleConfirmAction}
      />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const AlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  margin-bottom: 100px;
`;

const HEADER_TEXT = {
  workspace: {
    title: (
      <>
        Welcome to Swen!
        <br />
        Now, let's get to work.
      </>
    ),
    subtitle: <>Please create your workspace.</>,
  },
};

const Text = styled.p`
  padding-top: 2rem;
  font-size: 16px;
  line-height: 19px;
`;

const FORM_DATA = {
  create: {
    title: 'Give it a name!',
    type: 'create',
  },
  join: {
    title: 'Already know your workspace name?',
    type: 'join',
  },
};

const TextButton = styled.button`
  width: 100%;
  margin-top: 14px;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.pb.cyanBlue};

  &:hover {
    font-weight: 500;
  }

  cursor: pointer;
`;
